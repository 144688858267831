const solutoIcons = require("@solublestudio/soluto-design-system/icons.js");

const icons = {
  ...solutoIcons,
  'hamburger-close': require('./src/img/icons/hamburger-close.svg'),
  'hamburger-default': require('./src/img/icons/hamburger-default.svg'),
  arrowDown: require("./src/img/icons/arrow-down.svg"),
  arrowLeft: require("./src/img/icons/arrow-left.svg"),
  arrowRight: require("./src/img/icons/arrow-right.svg"),
  arrowUp: require("./src/img/icons/arrow-up.svg"),
  automatizacion: require("./src/img/icons/automatizacion.svg"),
  connection: require("./src/img/icons/connection.svg"),
  digitalizacion: require("./src/img/icons/digitalizacion.svg"),
  flexGrid: require("./src/img/icons/flexgrid.svg"),
  grid: require("./src/img/icons/grid.svg"),
  herramientas: require("./src/img/icons/herramientas.svg"),
  minus: require("./src/img/icons/minus.svg"),
  plus: require("./src/img/icons/plus.svg"),
  sensorizacion: require("./src/img/icons/sensorizacion.svg"),
  visualizacion: require("./src/img/icons/visualizacion.svg"),
  digitaltwin: require("./src/img/icons/digitaltwin.svg"),
  isotype: require("./src/img/icons/isotype.svg"),
};

module.exports = icons;
