import React from 'react';

import { Icon, cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Picture, { PictureProps } from 'Picture';
import Link from 'Link';

import type { LinkProps } from 'Link';
import { navigate } from 'Link';

import styles from './styles.module.scss';

export interface CardContentProps {
    typename: string;
    image: PictureProps;
    title: string;
    description: string;
    fullImage?: boolean;
    link?: LinkProps;
}

const CardContent = ({
    image,
    title,
    description,
    fullImage,
    link,
}: CardContentProps) => {
    return (
        <div
            {...(!!link?.href && { onClick: () => navigate(link.href) })}
            className={cls(
                styles.cardContent,
                'd-flex flex-column-reverse justify-content-space-between',
            )}>
            <div
                className={cls(
                    'd-flex align-items-end justify-content-space-between p-md',
                    styles.titleWrapper,
                )}>
                {link ? (
                    <Link
                        {...link}
                        className={cls(
                            styles.title,
                            fullImage && styles.fullTitle,
                        )}>
                        <Heading
                            component="h4"
                            font="h100"
                            title={fullImage ? title : undefined}>
                            {title}
                        </Heading>
                    </Link>
                ) : (
                    <Heading
                        component="h4"
                        font="h100"
                        className={cls(
                            styles.title,
                            fullImage && styles.fullTitle,
                        )}
                        title={fullImage ? title : undefined}>
                        {title}
                    </Heading>
                )}
                <div
                    className={cls(
                        'position-relative',
                        'ml-md',
                        'mb-tiny',
                        styles.icons,
                    )}>
                    <Icon
                        name="plus"
                        size={24}
                        className={cls('position-absolute', styles.plus)}
                    />
                    <Icon
                        name="minus"
                        size={24}
                        className={cls('position-absolute', styles.minus)}
                    />
                </div>
            </div>
            <Picture
                image={image}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(!fullImage && styles.icon),
                }}
            />
            {link ? (
                <Link {...link} className={cls(styles.cardContentInfo, 'p-lg')}>
                    <Text component="div" font="b200">
                        {description}
                    </Text>
                </Link>
            ) : (
                <Text
                    component="div"
                    font="b200"
                    className={cls(styles.cardContentInfo, 'p-lg')}>
                    {description}
                </Text>
            )}
        </div>
    );
};

export default CardContent;
