import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';

import Link, { LinkProps } from 'Link';

import styles from './styles.module.scss';

export interface LinksListProps {
    links: LinkProps[];
    className?: string;
}

const LinksList: React.FC<LinksListProps> = ({ links, className }) => {
    return (
        <ul
            className={cls(
                styles['list'],
                'list-unstyled',
                'mb-none',
                'b200',
                'd-lg-flex',
                'justify-content-lg-end',
            )}>
            {links.map((link, i) => (
                <li key={i} className={cls('mb-xs')}>
                    <Link {...link} className={className}>
                        {link.label}
                    </Link>
                </li>
            ))}
        </ul>
    );
};

export default LinksList;
