import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';
import Text from '@ds/components/shared/Text';
import Picture, { PictureProps } from 'Picture';
import Link from 'Link';

import type { LinkProps } from 'Link';
import { navigate } from 'Link';

import styles from './styles.module.scss';

export interface CardImageProps {
    typename: string;
    image: PictureProps;
    title: string;
    category: string;
    link?: LinkProps;
    highlighted?: boolean;
}

const CardImage = ({
    image,
    title,
    category,
    link,
    highlighted,
}: CardImageProps) => {
    return (
        <div
            {...(!!link?.href && { onClick: () => navigate(link.href) })}
            className={cls(
                styles.cardImage,
                link && styles.hoverCard,
                highlighted ? styles.highlightedCard : styles.card,
                'p-xxs d-flex flex-column',
            )}>
            <Picture
                image={image}
                layout="full"
                objectFit="cover"
                classes={{
                    wrapper: cls(
                        'mb-xxs',
                        highlighted ? styles.bigImg : styles.smallImg,
                    ),
                }}
            />
            <div
                className={cls(
                    styles.text,
                    ' d-flex flex-column justify-content-space-between',
                )}>
                {link ? (
                    <Link
                        {...link}
                        className={cls('mb-xxs', styles.link, styles.title)}>
                        <Heading component="h4" font="b500">
                            {title}
                        </Heading>
                    </Link>
                ) : (
                    <Heading
                        component="h4"
                        font="b500"
                        className={cls('mb-xxs', styles.title)}>
                        {title}
                    </Heading>
                )}
                <Text component="div" font="b100" className="text-uppercase">
                    {category}
                </Text>
            </div>
        </div>
    );
};

export default CardImage;
