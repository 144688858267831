import React from 'react';

import {
    Container,
    Row,
    Col,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import TitleComponent from '@ds/components/shared/TitleComponent';
import CardContent, {
    CardContentProps,
} from '@ds/components/shared/Cards/Content';
import Text from '@ds/components/shared/Text';
import Button, { ButtonProps } from '@ds/components/shared/Button';
import Carousel from '@ds/components/shared/Carousel';

import styles from './styles.module.scss';

export interface CardsSectionProps {
    title: string;
    pretitle?: string;
    description?: string;
    cta?: ButtonProps;
    items: CardContentProps[];
    fullImage?: boolean;
}

const CardsSection = ({
    title,
    pretitle,
    items,
    cta,
    description,
    fullImage,
}: CardsSectionProps) => {
    const isMobile = useIsMobile();

    return (
        <Section dataTheme="bg-primary">
            <Container>
                <Row>
                    <Col col={{ lg: description ? 5 : 7 }}>
                        <TitleComponent pretitle={pretitle} title={title} />
                    </Col>
                    {description && (
                        <Col
                            offset={{ lg: 1 }}
                            col={{ lg: 6 }}
                            className={styles.description}>
                            <Text component="div" font="b400">
                                {description}
                            </Text>
                        </Col>
                    )}
                </Row>
                <Row className="mt-xxl">
                    {isMobile ? (
                        <Col className="mb-xxl">
                            <Carousel items={items}></Carousel>
                        </Col>
                    ) : (
                        items.map((item, index) => {
                            return (
                                <Col
                                    key={index}
                                    col={{ lg: 4, xl: 3 }}
                                    className="mb-xxl">
                                    <CardContent {...item} fullImage={fullImage} />
                                </Col>
                            );
                        })
                    )}
                    {cta && (
                        <Col>
                            <Button
                                {...cta}
                                variant="primary"
                                iconName="arrowRight"
                            />
                        </Col>
                    )}
                </Row>
            </Container>
        </Section>
    );
};

export default CardsSection;
