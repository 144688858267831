import React, { forwardRef } from 'react';

import {
    Heading as HeadingSoluto,
    cls,
} from '@solublestudio/soluto-design-system';

import type { HeadingProps as HeadingSolutoProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

import { Font } from '@ds/types/font';

export type HeadingProps = HeadingSolutoProps & {
    font: Font;
    uppercase?: boolean;
};

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
    ({ className, font = 'h100', uppercase, ...props }, ref) => {
        return (
            <HeadingSoluto
                ref={ref}
                className={cls(
                    styles['heading'],
                    styles[font],
                    uppercase && 'text-uppercase',
                    font,
                    className,
                )}
                {...props}
            />
        );
    },
);

export default Heading;
