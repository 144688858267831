import React, { Fragment, useMemo } from 'react';

import { Icon, cls } from '@solublestudio/soluto-design-system';
import Text from '@ds/components/shared/Text';

import styles from './styles.module.scss';

export interface BarComponentProps {
    items: string[] | string;
}

const BarComponent = ({ items }: BarComponentProps) => {
    const finalItems = useMemo(() => {
        return typeof items === 'string'
            ? items
                  .split(',')
                  .map((item) => item.trim())
                  .filter((item) => !!item)
            : items;
    }, [items]);

    return (
        <div className={cls('bg-secondary text-primary', styles.barComponent)}>
            <div className={cls(styles.barItems, 'py-tiny')}>
                {finalItems.map((item, index) => {
                    return (
                        <Fragment key={`${index}-0`}>
                            <Text
                                font="h100"
                                className={cls(
                                    'text-uppercase mx-lg',
                                    styles.text,
                                )}>
                                {item}
                            </Text>
                            <Icon
                                className={styles.icon}
                                name="isotype"
                                size={32}></Icon>
                        </Fragment>
                    );
                })}
                {finalItems.map((item, index) => {
                    return (
                        <Fragment key={`${index}-1`}>
                            <Text
                                font="h100"
                                className={cls(
                                    'text-uppercase mx-lg',
                                    styles.text,
                                )}>
                                {item}
                            </Text>
                            <Icon
                                className={styles.icon}
                                name="isotype"
                                size={32}></Icon>
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default BarComponent;
