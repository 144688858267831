import React, { useMemo, useRef } from 'react'
import {
    Header as SolutoHeader,
    HeaderRef,
    cls,
} from '@solublestudio/soluto-design-system'

import type { HeaderProps as SolutoHeaderProps } from '@solublestudio/soluto-design-system'
import type { NavigationItemProps } from '@solublestudio/soluto-design-system'

import Logo from '@ds/img/logo.svg?url'
import LogoWhite from '@ds/img/logo-white.svg?url'

import styles from './styles.module.scss'
import { SectionProps } from '../Section'
import LinksList, { LinksListProps } from '../LinksList'

export interface HeaderProps
    extends Omit<
        SolutoHeaderProps,
        | 'kind'
        | 'logo'
        | 'sticky'
        | 'navs'
        | 'classes'
    > {
    nav?: NavigationItemProps[]
    dataTheme?: SectionProps['dataTheme']
    footerMobileLinks?: LinksListProps['links']
}

function Header({
    nav,
    dataTheme,
    footerMobileLinks,
    ...props
}: HeaderProps) {
    const ref = useRef<HeaderRef>(null)

    const itemsWithOnClick = nav?.map((n) => ({
        ...n,
        onClick: () => ref.current?.close()
    }))

    const navs = useMemo(() => {
        return [
            nav && {
                classes: { wrapper: styles['nav-main'] },
                items: itemsWithOnClick,
            },
        ].filter((i) => !!i)
    }, [itemsWithOnClick, nav])

    return (
        <SolutoHeader
            {...props}
            ref={ref}
            kind="default"
            data-theme={dataTheme}
            logo={{
                src: {
                    black: dataTheme === 'bg-primary' ? LogoWhite : Logo,
                    white: LogoWhite,
                },
                link: {
                    href: '/',
                    title: 'Adaion',
                },
            }}
            sticky="top"
            navs={navs}
            classes={{
                root: cls(styles.root),
                logo: cls(styles.logo),
                navigationItem: styles['nav-item'],
                navigationItemActive: styles['nav-item-active'],
                collapseContent: styles['collapse-content'],
                footerMobile: styles['footer-mobile'],
            }}
            {...(!!footerMobileLinks?.length && {
                footerMobile: <LinksList links={footerMobileLinks} className={styles['footer-mobile-links']} />,
            })}
        />
    )
}

export default Header
