import React from 'react';

import { Container, Row, Col, cls } from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import TitleComponent from '@ds/components/shared/TitleComponent';
import { ButtonProps } from '@ds/components/shared/Button';

import styles from './styles.module.scss';
import Picture from 'Picture';

export interface TextImageSectionProps {
    title: string;
    cta: ButtonProps;
    image: any;
}

const TextImageSection = ({ title, cta, image }: TextImageSectionProps) => {
    return (
        <Section>
            <Container>
                <Row>
                    <Col
                        col={{ lg: 5 }}
                        className={cls(
                            'd-lg-flex flex-lg-column justify-content-lg-center align-items-lg-start',
                            styles.text,
                        )}>
                        <TitleComponent title={title} cta={cta} />
                    </Col>
                    <Col offset={{ lg: 1 }} col={{ lg: 6 }}>
                        <Picture image={image} layout="full" />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default TextImageSection;
