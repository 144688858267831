import React from 'react';

import { Image, Container, cls } from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

export interface GraphicSectionProps {
    title: string;
    image: any;
}

const GraphicSection = ({ title, image }: GraphicSectionProps) => {
    return (
        <Section>
            <Container>
                <Heading component="h4" font="h100" className="mb-xxl">
                    {title}
                </Heading>
                <div className={cls(styles.imageWrapper)}>
                    <Image src={image} className={cls(styles.image)} />
                </div>
            </Container>
        </Section>
    );
};

export default GraphicSection;
