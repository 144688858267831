import React from 'react';
import {
    Section as SectionSoluto,
    SectionProps as SectionSolutoProps,
    cls,
} from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

export interface SectionProps extends SectionSolutoProps {
    dataTheme?: 'bg-primary' | 'bg-secondary' | '';
    children: React.ReactNode;
    className?: string;
    anchor?: string;
}
const Section = ({
    dataTheme,
    children,
    className,
    ...props
}: SectionProps) => {
    return (
        <SectionSoluto
            className={cls(
                styles.section,
                'position-relative',
                'pt-xxxl',
                'pb-xxxl',
                className,
            )}
            data-theme={dataTheme ? dataTheme : ''}
            {...props}>
            {children}
        </SectionSoluto>
    );
};

export default Section;
