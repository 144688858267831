import React from 'react';

import CardContent from '@ds/components/shared/Cards/Content';
import CardImage from '@ds/components/shared/Cards/Image';

export type Component = {
    typename: string;
    id?: string;
    originalId?: string;
    internal?: { type: string };

    [key: string]: any;
};

const Components: { [key: string]: React.ElementType } = {
    DatoCmsCardImage: CardImage,
    DatoCmsCardContent: CardContent,
};

const DynamicCarouselCard: React.FC<Component> = ({
    typename: __typename,
    id,
    originalId,
    internal = {},
    i,
    ...props
}) => {
    const typename = __typename ?? internal?.type ?? '';

    if (typeof Components[typename] !== 'undefined') {
        const Component = Components[typename];

        return <Component {...props} />;
    }
    return (
        <code>
            The component <strong>{typename}</strong> has not been created yet.
        </code>
    );
};

export default DynamicCarouselCard;
