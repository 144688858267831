import React from 'react';

import GetFormForm from './GetFormForm';

import styles from './styles.module.scss';

export interface FormProps {
    className?: string;
    formId: string;
}

const Form = ({}: FormProps) => {
    return (
        <GetFormForm 
            url="https://getform.io/f/5606f5e5-4ef1-4ab3-a37a-e1496e769fce"
            policyUrl="https://adaion.energy/privacy-policy/"
        />
    );
};

export default Form;
