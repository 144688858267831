import React from 'react';

import { Container, Row, Col } from '@solublestudio/soluto-design-system';
import Picture from 'Picture';

import Section from '@ds/components/shared/Section';
import TitleComponent from '@ds/components/shared/TitleComponent';
import Text from '@ds/components/shared/Text';

export interface TextLandscapeSectionProps {
    pretitle?: string;
    title: string;
    description?: string;
    image: any;
}

const TextLandscapeSection = ({
    pretitle,
    title,
    description,
    image,
}: TextLandscapeSectionProps) => {
    return (
        <Section>
            <Container>
                <Row>
                    <Col col={{ lg: 5 }}>
                        <TitleComponent pretitle={pretitle} title={title} />
                    </Col>
                    {description && (
                        <Col
                            offset={{ lg: 1 }}
                            col={{ lg: 6 }}
                            className="mt-md">
                            <Text component="div" font="b400">
                                {description}
                            </Text>
                        </Col>
                    )}
                    <Col className="mt-xxl">
                        <Picture image={image} layout="full" />
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default TextLandscapeSection;
