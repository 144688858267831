import React from 'react'
import {
    Container,
    Row,
    Col,
    cls,
    HTMLComponent,
    Heading,
} from '@solublestudio/soluto-design-system'

import type { HTMLComponentProps } from '@solublestudio/soluto-design-system'

import styles from './styles.module.scss'
import Section from '@ds/components/shared/Section'

export interface HTMLSectionProps extends Pick<HTMLComponentProps, 'text'> {
    title: string
    anchor?: string
}

const HTMLSection: React.FC<HTMLSectionProps> = ({
    title,
    text,
    anchor,
}) => {
    return (
        <Section anchor={anchor}>
            <Container>
                <Row>
                    {title && (
                        <Col>
                            <Heading
                                component="h1"
                                className="h300 mb-md">
                                {title}
                            </Heading>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <HTMLComponent
                            text={text}
                            className={styles.htmlSection}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>
    )
}

export default HTMLSection
