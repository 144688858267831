import React, { useMemo } from 'react';
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source';
import { Footer as FooterDS } from '@solublestudio/adaion-design-system';

interface NavItem {
    childLinks: NavChildItem[];
}

interface NavChildItem extends NavItem {
    label: string;
    slug: string;
}

interface FooterProps {
    navs: NavItem[];
    extraParams: {
        [key: string]: any;
    };
}

const Footer: React.FunctionComponent<FooterProps> = function Footer({
    navs,
    extraParams = {},
}) {
    const _ = useTranslate();

    const { minimal, showDivider } = extraParams;

    const links = useMemo(() => {
        return navs?.length && navs[0].childLinks?.length
            ? navs[0].childLinks.map((item) => ({
                  label: item.label,
                  href: item.slug,
              }))
            : [];
    }, [navs]);

    return (
        <FooterDS
            links={links}
            title={_['footer.title'].text}
            formId={_['footer.formId'].text}
            minimal={!!minimal}
            showDivider={!!showDivider}
        />
    );
};

export default Footer;
