import React, { useState, useEffect } from 'react';

import { Container, Row, Col } from '@solublestudio/soluto-design-system';

import Section, { SectionProps } from '@ds/components/shared/Section';
import Heading from '@ds/components/shared/Heading';
import Form from '@ds/components/shared/Form';
import Text from '@ds/components/shared/Text';
import BarComponent, {
    BarComponentProps,
} from '@ds/components/shared/BarComponent';

import styles from './styles.module.scss';

export interface ContactSectionProps {
    title: string;
    formId: string;
    text?: string;
    textForm?: string;
    categories?: BarComponentProps['items'];
    viewBar?: boolean;
    dataTheme?: SectionProps['dataTheme'];
    component?: SectionProps['component'];
}

const ContactSection = ({
    title,
    formId,
    text,
    textForm,
    categories,
    viewBar,
    dataTheme,
    component = 'section',
}: ContactSectionProps) => {
    const [showText, setShowText] = useState(true);

    useEffect(() => {
        if (typeof window === 'undefined' || !textForm) {
            return;
        }

        const onSubmit = () => {
            setShowText(false);
        };

        window.document.addEventListener('af-submitForm-success', onSubmit);
        return () => {
            window.document.removeEventListener(
                'af-submitForm-success',
                onSubmit,
            );
        };
    }, [setShowText, textForm]);

    return (
        <>
            <Section
                dataTheme={dataTheme}
                className={styles.contactSection}
                component={component}>
                <Container>
                    <Row>
                        <Col col={{ lg: 6 }} className={styles.titleCol}>
                            <Heading
                                component={text ? 'h1' : 'h2'}
                                font={text ? 'h400' : 'h300'}>
                                {title}
                            </Heading>
                            {text && (
                                <Text
                                    component="div"
                                    font="b500"
                                    className="mt-xl">
                                    {text}
                                </Text>
                            )}
                        </Col>
                        <Col col={{ lg: 6 }}>
                            {textForm && showText && (
                                <Text
                                    component="div"
                                    font="b500"
                                    className="py-xl">
                                    {textForm}
                                </Text>
                            )}
                            <Form formId={formId}></Form>
                        </Col>
                    </Row>
                </Container>
            </Section>
            {viewBar && categories && <BarComponent items={categories} />}
        </>
    );
};

export default ContactSection;
