import React from 'react';

export interface FormProps {
    className?: string;
    url: string;
    policyUrl: string;
}

const GetFormForm = ({
    className,
    url,
    policyUrl,
}: FormProps) => {
    return (
        <form className="get-form" action={url} method="POST">
            <label className="get-form-label">
                Connect with us!
                <input type="email" name="email" placeholder='Email' />
            </label>
            
            <label>
                <input type="checkbox" id="cbox1" value="first_checkbox" />
                <p>I agree to the processing of my personal data for marketing purposes, in accordance with <a href={policyUrl}>Privacy Policy</a>.</p>
            </label>


            <button className='get-form-button' type="submit">Send</button>
        </form>
    );
};

export default GetFormForm;
