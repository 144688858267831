import React from 'react'
import { HeroSection as HeroSectionDS } from '@solublestudio/adaion-design-system'
import { graphql } from 'gatsby'
import { useTranslate } from '@solublestudio/gatsby-theme-soluble-source'

export default function HeroSection(props) {
    const translation = useTranslate()
    return (
        <HeroSectionDS categories={translation['categories'].text}  {...props} />
    )
}

export const query = graphql`
    fragment DatoCmsHeroSection on DatoCmsHeroSection {
        pretitle
        title
        background {
            file {
                publicURL
                extension
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            isImage
        }
        text
        alignCenter
        themeDefault
        canvas
    }
`
