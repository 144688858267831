import React from 'react';

import { Slider, SliderProps } from '@solublestudio/soluto-design-system';

import DynamicCarouselCard from './DynamicCarouselCard';
import type { Component } from './DynamicCarouselCard';
import styles from './styles.module.scss';
export interface CarouselProps extends SliderProps {
    items: Component[];
    className?: string;
}

const Carousel = ({ items, className }: CarouselProps) => {
    const settings = {
        controls: false,
        nav: true,
        navPosition: 'bottom',
        loop: true,
        edgePadding: 20,
        gutter: 20,
        responsive: {
            992: {
                edgePadding: 0,
            },
        },
    };

    return (
        <div className={styles.wrapper}>
            <Slider {...(!!className && { className })} settings={settings}>
                {items &&
                    items.map((item, i) => (
                        <div key={i}>
                            <DynamicCarouselCard {...item} />
                        </div>
                    ))}
            </Slider>
        </div>
    );
};

export default Carousel;
