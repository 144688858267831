import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';

import styles from './styles.module.scss';

export interface CardSquareProps {
    text: string;
    isActive?: boolean;
    className?: string;
    number?: number;
}

const CardSquare = ({
    text,
    isActive,
    className,
    number = 0,
}: CardSquareProps) => {
    return (
        <div
            {...(!!isActive && {
                'data-theme': 'bg-primary',
            })}
            data-number={number}
            className={cls(styles.wrapper, className)}>
            <div
                className={cls(
                    styles.square,
                    'd-flex align-items-center justify-content-center',
                )}>
                <Heading
                    component="h3"
                    font="h100"
                    className={cls(styles.title)}>
                    {text}
                </Heading>
            </div>
        </div>
    );
};

export default CardSquare;
