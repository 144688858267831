import React, { useMemo } from 'react';

import {
    Container,
    Row,
    Col,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import Section from '@ds/components/shared/Section';
import TitleComponent from '@ds/components/shared/TitleComponent';
import CardImage, { CardImageProps } from '@ds/components/shared/Cards/Image';

import styles from './styles.module.scss';
import Carousel from '@ds/components/shared/Carousel';
export interface GridCardsSectionProps {
    title: string;
    pretitle?: string;
    items: CardImageProps[];
}

const GridCardsSection = ({
    title,
    pretitle,
    items = [],
}: GridCardsSectionProps) => {
    const isMobile = useIsMobile();

    const highlightedItem = useMemo(
        () => (items?.length ? items[0] : undefined),
        [items],
    );
    const notHighlightedItems = useMemo(
        () => items.filter((item, index) => index !== 0) ?? [],
        [items],
    );

    return (
        <Section dataTheme="bg-primary">
            <Container>
                <Row>
                    <Col col={{ lg: 7 }}>
                        <TitleComponent pretitle={pretitle} title={title} />
                    </Col>
                </Row>
                <Row className="mt-xxl">
                    {isMobile ? (
                        <Col>
                            <Carousel
                                className={styles.carousel}
                                items={items}
                            />
                        </Col>
                    ) : (
                        <>
                            <Col col={{ lg: 5 }} className="d-flex">
                                {highlightedItem ? (
                                    <CardImage
                                        {...highlightedItem}
                                        highlighted
                                    />
                                ) : null}
                            </Col>
                            <Col col={{ lg: 7 }} className={styles.cards}>
                                {notHighlightedItems.map((item, index) => {
                                    return <CardImage {...item} key={index} />;
                                })}
                            </Col>
                        </>
                    )}
                </Row>
            </Container>
        </Section>
    );
};

export default GridCardsSection;
