import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Icon, cls } from '@solublestudio/soluto-design-system'
import Heading from '@ds/components/shared/Heading'
import Text from '@ds/components/shared/Text'

import styles from './styles.module.scss'
export interface FeatureComponentProps {
    icon: string
    title: string
    description: string
    blogImage: any
}

const FeatureComponent = ({
    icon,
    title,
    description,
    blogImage,
}: FeatureComponentProps) => {
    return (
        <div className={cls(styles.featureComponent)}>
            {blogImage && <img src={blogImage.fluid.src} alt={title} style={{
                width: '100%',
                height: 'auto',
            }} />}
            <div className={cls('d-flex mb-xxs')}>
                {icon && <Icon name={icon} size={40} />}
                <Heading
                    component="h4"
                    font="h100"
                    className={icon && 'ml-xxs'}>
                    {title}
                </Heading>
                G</div>
            <Text component="div" font="b300">
                {description}
            </Text>
        </div>
    )
}

export default FeatureComponent
