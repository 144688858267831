import React from 'react';
import {
    cls,
    Container,
    useInViewport,
} from '@solublestudio/soluto-design-system';

import ContactSection from '@ds/components/organisms/ContactSection';
import LinksList from '@ds/components/shared/LinksList';
import Canvas from '@ds/components/organisms/HeroSection/Canvas';

import type { LinkProps } from 'Link';

import styles from './styles.module.scss';

export interface FooterProps {
    title: string;
    formId: string;
    links: LinkProps[];
    showDivider?: boolean;
    minimal?: boolean;
}

const Footer = ({
    title,
    formId,
    links,
    minimal = false,
    showDivider = false,
}: FooterProps) => {
    const [nodeRef, isVisible] = useInViewport();

    return (
        <footer className={cls(showDivider && styles.divider)}>
            {!minimal && <ContactSection title={title} formId={formId} />}
            <div
                ref={nodeRef}
                className={cls(styles.canvasWrapper, isVisible && styles.show)}>
                <Canvas
                    lessDefinition
                    cameraY={10}
                    cameraYTo={20}
                    className={cls(styles.canvas)}
                    start={isVisible}
                />
                <script>{`
                <noscript>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-575VDKC9"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>
                </noscript>
            `}
            </script>
            </div>
            <nav className={cls('d-none', 'd-lg-block', 'pt-xl', 'pb-huge')}>
                <Container>
                    <LinksList links={links} />
                </Container>
            </nav>
        </footer>
    );
};

export default Footer;
