import React from 'react';

import { cls } from '@solublestudio/soluto-design-system';
import Heading from '@ds/components/shared/Heading';

import Button, { ButtonProps } from '../Button';

export interface TitleComponentProps {
    title: string;
    pretitle?: string;
    cta?: ButtonProps;
}

const TitleComponent = ({ title, pretitle, cta }: TitleComponentProps) => {
    return (
        <>
            {pretitle && (
                <Heading
                    component="h2"
                    font="b200"
                    className={cls('text-uppercase mb-tiny')}>
                    {pretitle}
                </Heading>
            )}
            <Heading component={pretitle ? 'p' : 'h2'} font={'h300'}>
                {title}
            </Heading>
            {cta && (
                <Button
                    {...cta}
                    variant="primary"
                    iconName="arrowRight"
                    className="mt-lg"
                />
            )}
        </>
    );
};

export default TitleComponent;
