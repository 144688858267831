import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Header as HeaderDS } from '@solublestudio/adaion-design-system';

import '@solublestudio/adaion-design-system/src/scss/cookiehub.module.scss';

export default function Header({ navs, isDark }) {
    const footerLinks = useStaticQuery(graphql`
        query {
            footer: allLink(
                filter: {
                    templateKey: { eq: "_link-footer" }
                    parentId: { ne: null }
                }
                sort: { fields: order, order: ASC }
            ) {
                edges {
                    node {
                        ...Link
                    }
                }
            }
        }
    `);

    const footerMobileLinks = useMemo(() => {
        return (
            footerLinks?.footer?.edges?.map((e) => ({
                label: e.node.label,
                href: e.node.slug,
            })) || []
        );
    }, [footerLinks]);

    const nav = useMemo(() => {
        return (
            navs?.map((item) => ({
                children: item.label,
                href: item.slug || '/',
            })) || []
        );
    }, [navs]);

    return (
        <HeaderDS
            footerMobileLinks={footerMobileLinks}
            nav={nav}
            dataTheme={isDark ? 'bg-primary' : ''}
        />
    );
}
