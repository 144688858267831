import React from 'react'

import { Row, Col, cls, Container } from '@solublestudio/soluto-design-system'

import Section from '@ds/components/shared/Section'
import MediaBackgroundComponent from '@ds/components/shared/MediaBackground'
import Heading from '@ds/components/shared/Heading'
import Text from '@ds/components/shared/Text'
import Canvas from './Canvas'
import BarComponent, {
    BarComponentProps,
} from '@ds/components/shared/BarComponent'

import styles from './styles.module.scss'

export interface HeroSectionProps {
    pretitle?: string
    title: string
    text?: string
    background?: any
    canvas?: boolean
    alignCenter?: boolean
    themeDefault?: boolean
    childrenLeft?: React.ReactNode
    childrenRight?: React.ReactNode
    className?: string
    categories?: BarComponentProps['items']
}

const HeroSection = ({
    pretitle,
    title,
    text,
    background,
    canvas = true,
    alignCenter = false,
    themeDefault = false,
    childrenLeft,
    childrenRight,
    className,
    categories,
}: HeroSectionProps) => {
    return (
        <Section
            dataTheme={themeDefault ? '' : 'bg-primary'}
            className={cls(
                styles.heroSection,
                'd-flex flex-column',
                className,
            )}>
            <div
                className={cls(
                    styles.bg,
                    canvas && styles.heroGradient,
                    'position-relative d-flex flex-column',
                    alignCenter
                        ? 'justify-content-center'
                        : 'justify-content-end',
                    'pt-xl pb-xl',
                )}>
                {background ? (
                    <MediaBackgroundComponent background={background} />
                ) : canvas ? (
                    <Canvas dark className={styles.canvas} />
                ) : null
                }
                <Container className={styles.heroContainer}>
                    <Row>
                        <Col
                            col={{ lg: 6 }}
                            className={cls(
                                !alignCenter &&
                                'd-flex flex-column justify-content-end',
                            )}>
                            {pretitle && (
                                <Text
                                    component="p"
                                    font="b200"
                                    className={cls(
                                        'mb-tiny',
                                        'text-uppercase',
                                    )}>
                                    {pretitle}
                                </Text>
                            )}
                            <Heading
                                component="h1"
                                font="h400"
                                className={cls('text-break')}>
                                {title}
                            </Heading>
                            {childrenLeft && childrenLeft}
                        </Col>
                        <Col
                            col={{ lg: 6 }}
                            className={cls(
                                'pt-md',
                                !alignCenter &&
                                'd-flex flex-column justify-content-end',
                            )}>
                            {text && (
                                <Text component="div" font="b500">
                                    {text}
                                </Text>
                            )}
                            {childrenRight && childrenRight}
                        </Col>
                    </Row>
                </Container>
            </div>
            {categories && <BarComponent items={categories} />}
        </Section>
    )
}

export default HeroSection
