import React from 'react';

import { cls, VideoInline } from '@solublestudio/soluto-design-system';

import Picture from 'Picture';

import styles from './styles.module.scss';

export interface MediaBackgroundComponentProps {
    background: any;
    classes?: {
        video?: string;
        imgWrapper?: string;
        img?: string;
        wrapper?: string;
    };
}

const MediaBackgroundComponent = ({
    background,
    classes,
}: MediaBackgroundComponentProps) => {
    return (
        <div
            className={cls(
                styles.wrapper,
                classes?.wrapper,
            )}>
            {background &&
                (!background?.isImage ? (
                    <VideoInline
                        className={cls(styles.video, classes?.video)}
                        sources={[
                            {
                                src: background?.file?.publicURL,
                                type: background?.file?.extension,
                            },
                        ]}
                    />
                ) : background ? (
                    <Picture
                        priority
                        image={background}
                        alt={background.alt}
                        layout="background"
                        objectFit="cover"
                        classes={{
                            wrapper: cls(
                                classes?.imgWrapper,
                                styles.imgWrapper,
                            ),
                            picture: cls(classes?.img, styles.img),
                        }}
                    />
                ) : null)}
        </div>
    );
};

export default MediaBackgroundComponent;
