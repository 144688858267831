import React from 'react'

import { Container, Row, Col } from '@solublestudio/soluto-design-system'

import Section from '@ds/components/shared/Section'
import TitleComponent from '@ds/components/shared/TitleComponent'
import FeatureComponent, {
    FeatureComponentProps,
} from '@ds/components/shared/FeatureComponent'
import Heading from '@ds/components/shared/Heading'
import Button, { ButtonProps } from '@ds/components/shared/Button'

export interface FeaturesSectionProps {
    title: string
    subtitle: string
    cta?: ButtonProps
    features: FeatureComponentProps[]
}

const FeaturesSection = ({
    title,
    subtitle,
    features,
    cta,
}: FeaturesSectionProps) => {
    return (
        <Section>
            <Container>
                {(title || subtitle) && (
                    <Row>
                        <Col col={{ lg: 7 }}>
                            {title && <TitleComponent title={title} />}
                            {subtitle && (
                                <Heading
                                    component="h3"
                                    font="h100"
                                    className="mt-xxxl">
                                    {subtitle}
                                </Heading>
                            )}
                        </Col>
                    </Row>
                )}
                <Row className="mt-xxl">
                    {features.map((item, index) => {
                        return (
                            <Col key={index} col={{ lg: 4 }} className="mb-xxl">
                                <FeatureComponent {...item} />
                            </Col>
                        )
                    })}
                    {cta && (
                        <Col>
                            <Button
                                variant="primary"
                                iconName="arrowRight"
                                {...cta}
                            />
                        </Col>
                    )}
                </Row>
            </Container>
        </Section>
    )
}

export default FeaturesSection
