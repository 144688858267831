import React, { useEffect, useState, useRef } from 'react';

import {
    Section,
    Container,
    cls,
    Row,
    Col,
    useIsMobile,
} from '@solublestudio/soluto-design-system';

import TitleComponent, {
    TitleComponentProps,
} from '@ds/components/shared/TitleComponent';
import CardSquare, {
    CardSquareProps,
} from '@ds/components/shared/Cards/Square';

import styles from './styles.module.scss';
import MediaBackgroundComponent from '@ds/components/shared/MediaBackgroundComponent';

export interface CardsSquareSectionProps {
    background?: any;
    title: TitleComponentProps['title'];
    pretitle: TitleComponentProps['pretitle'];
    items: CardSquareProps[];
}

const CardsSquareSection = ({
    background,
    title,
    pretitle,
    items,
}: CardsSquareSectionProps) => {
    const [activeItem, setActiveItem] = useState(0);
    const isMobile = useIsMobile();
    const mobileScrollRef = useRef();

    useEffect(() => {
        if (typeof window === 'undefined' || !mobileScrollRef?.current) {
            return;
        }

        let observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveItem(
                            parseInt(entry.target.dataset.number || 0),
                        );
                    }
                });
            },
            {
                threshold: isMobile ? 0.6 : 1,
            },
        );

        [...mobileScrollRef.current.childNodes].forEach((item) => {
            observer.observe(item);
        });

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [setActiveItem, mobileScrollRef, isMobile]);

    return (
        <Section className={cls(styles.section, 'bg-base-1000')}>
            {background && (
                <MediaBackgroundComponent
                    classes={{ wrapper: cls('d-lg-none') }}
                    background={background}
                />
            )}
            <div className={styles.sticky}>
                {background && (
                    <MediaBackgroundComponent
                        classes={{ wrapper: cls('d-none d-lg-block') }}
                        background={background}
                    />
                )}
                <Container>
                    <Row>
                        <Col col={{ lg: 7 }}>
                            <TitleComponent title={title} pretitle={pretitle} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className={styles.container}>
                <Row>
                    <Col offset={{ lg: 7 }} col={{ lg: 4 }}>
                        <div className={styles.squaresWrapper}>
                            <div ref={mobileScrollRef}>
                                {items.map((item, index) => {
                                    return (
                                        <CardSquare
                                            key={index}
                                            {...item}
                                            className={styles.square}
                                            number={index}
                                            isActive={activeItem === index}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default CardsSquareSection;
