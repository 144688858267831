import React from 'react';

import { Container, Row, Col, cls } from '@solublestudio/soluto-design-system';
import Picture, { PictureProps } from 'Picture';

import Section from '@ds/components/shared/Section';
import TitleComponent from '@ds/components/shared/TitleComponent';

import styles from './styles.module.scss';
export interface TextLogosSectionProps {
    pretitle: string;
    title: string;
    logos: PictureProps[];
}

const TextLogosSection = ({
    pretitle,
    title,
    logos,
}: TextLogosSectionProps) => {
    return (
        <Section dataTheme="bg-primary">
            <Container>
                <Row>
                    <Col
                        col={{ lg: 5 }}
                        className={cls(
                            styles.text,
                            'd-lg-flex flex-lg-column justify-content-lg-center',
                        )}>
                        <TitleComponent pretitle={pretitle} title={title} />
                    </Col>
                    <Col
                        offset={{ lg: 1 }}
                        col={{ lg: 6 }}
                        className={styles.logos}>
                        {logos.map((logo, index) => {
                            return (
                                <Picture
                                    key={`logo-item-${index}`}
                                    image={logo}
                                />
                            );
                        })}
                    </Col>
                </Row>
            </Container>
        </Section>
    );
};

export default TextLogosSection;
