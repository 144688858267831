import React, { forwardRef } from 'react';

import { Text as TextSoluto, cls } from '@solublestudio/soluto-design-system';

import type { TextProps as TextSolutoProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

import { Font } from '@ds/types/font';

export type TextProps = TextSolutoProps & {
    font: Font;
    uppercase?: boolean;
};

const Text = forwardRef<HTMLDivElement, TextProps>(
    ({ className, font, uppercase, ...props }, ref) => {
        return (
            <TextSoluto
                ref={ref}
                className={cls(
                    styles['text'],
                    styles[font],
                    uppercase && 'text-uppercase',
                    font,
                    className
                )}
                {...props}
            />
        );
    },
);

export default Text;
