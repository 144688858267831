import React, { forwardRef, useMemo } from 'react';

import {
    Button as ButtonSoluto,
    Icon,
    cls,
} from '@solublestudio/soluto-design-system';

import type { ButtonProps as ButtonSolutoProps } from '@solublestudio/soluto-design-system';

import styles from './styles.module.scss';

type VariantButton = 'primary' | 'secondary';

export interface ButtonProps extends ButtonSolutoProps {
    variant: VariantButton;
    iconName?: string;
    className?: string;
    label?: string;
    link?: any;
    externalLink?: string;
    detached?: boolean;
}

const variantsIconSize: { [key in VariantButton]: number } = {
    primary: 24,
    secondary: 24,
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
    function Button(
        {
            className,
            iconName,
            variant,
            label,
            link,
            externalLink,
            detached,
            ...props
        },
        ref,
    ) {
        const finalHref = useMemo(
            () => (link?.slug ? link.slug : externalLink ? externalLink : '#'),
            [externalLink, link],
        );

        return (
            <ButtonSoluto
                ref={ref}
                {...props}
                variant={variant}
                href={finalHref}
                className={cls(
                    styles['button'],
                    'd-inline-flex',
                    'align-items-center',
                    styles[variant as string],
                    className,
                )}
                afterContent={
                    iconName ? (
                        <Icon
                            name={iconName}
                            size={variantsIconSize[variant]}
                            classes={{
                                icon: cls(
                                    styles.icon,
                                    'd-flex',
                                    'align-items-center',
                                    'justify-content-center',
                                    'ml-tiny',
                                ),
                            }}
                        />
                    ) : undefined
                }>
                {label}
            </ButtonSoluto>
        );
    },
);

export default Button;
