import React, { useRef, useEffect, useState } from 'react';
import {
    WebGLRenderer,
    Scene,
    Fog,
    PerspectiveCamera,
    Object3D,
    TextureLoader,
    Vector3,
    Geometry,
    PlaneGeometry,
    Matrix4,
    MeshBasicMaterial,
    PointsMaterial,
    Points,
    DoubleSide,
    Mesh,
} from 'three';
import { TweenMax, Power0, Power1 } from 'gsap';
import textureImageLight from './rombo-light.svg';
import textureImageDark from './rombo-dark.svg';

const Canvas = ({
    className,
    dark,
    cameraY = 30,
    cameraYTo = 80,
    lessDefinition = false,
    start = true,
}: {
    className?: string;
    dark?: boolean;
    cameraY?: number;
    cameraYTo?: number;
    lessDefinition?: boolean;
    start?: boolean;
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [definition, setDefinition] = useState<number | null>(null);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const onResize = () => {
            const ww = window.innerWidth;
            if (ww <= 992) {
                setDefinition(lessDefinition ? 0.2 : 0.33);
            } else if (ww <= 1280) {
                setDefinition(lessDefinition ? 0.33 : 0.5);
            } else {
                setDefinition(lessDefinition ? 0.5 : 0.66);
            }
        };

        onResize();

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [setDefinition, lessDefinition]);

    useEffect(() => {
        if (
            !canvasRef?.current ||
            typeof window === 'undefined' ||
            !definition ||
            !start
        ) {
            return;
        }

        const CAMERA_Z = 100;
        const DEFINITION = definition;
        const BG_COLOR = dark ? 0x110079 : 0xffffff;

        var canvas = canvasRef.current,
            parent = canvas.parentElement;

        function getParentSizes() {
            return [parent?.offsetWidth, parent?.offsetHeight];
        }

        const [ww, wh] = getParentSizes();

        var renderer = new WebGLRenderer({
            canvas: canvas,
            antialias: true,
        });
        renderer.setSize(ww, wh);
        renderer.setClearColor(BG_COLOR);

        var scene = new Scene();
        scene.fog = new Fog(BG_COLOR, 100, 160);

        var camera = new PerspectiveCamera(45, ww / wh, 0.1, 1000);
        camera.position.y = cameraY;
        camera.position.z = CAMERA_Z;
        TweenMax.to(camera.position, 60, {
            z: CAMERA_Z - 50,
            y: cameraYTo,
            yoyo: true,
            ease: Power1.easeInOut,
            repeatDelay: 5,
            repeat: -1,
        });

        var container = new Object3D();
        scene.add(container);

        TweenMax.to(container.rotation, 88, {
            y: Math.PI * 2,
            ease: Power0.easeNone,
        });

        var loader = new TextureLoader();
        /* Options */
        var dots, plane;
        var width = 150,
            height = 150;
        var center = new Vector3(0, 0, 0);
        var maxDistance = new Vector3(width * 0.5, height * 0.5).distanceTo(
            center,
        );

        function createDots() {
            var geom = new Geometry();

            const WIDTH_FOR_DEFINITION = Math.round(width * DEFINITION);
            const HEIGHT_FOR_DEFINITION = Math.round(height * DEFINITION);
            const POINTS_EACH = Math.floor(1 / DEFINITION);

            var planeGeom = new PlaneGeometry(
                width * 2,
                height * 2,
                WIDTH_FOR_DEFINITION,
                HEIGHT_FOR_DEFINITION,
            );
            var m = new Matrix4();
            m.makeRotationX(-Math.PI * 0.5);
            planeGeom.applyMatrix4(m);

            for (var i = 0; i < planeGeom.vertices.length; i++) {
                var vector = planeGeom.vertices[i];
                vector.dist = vector.distanceTo(center);
                vector.ratio =
                    (maxDistance - vector.dist) / (maxDistance * 0.9);
            }

            var planeMat = new MeshBasicMaterial({
                color: BG_COLOR,
                side: DoubleSide,
            });
            plane = new Mesh(planeGeom, planeMat);
            container.add(plane);

            for (var x = -width * 0.5; x < width * 0.5; x += POINTS_EACH) {
                for (
                    var z = -height * 0.5;
                    z < height * 0.5;
                    z += POINTS_EACH
                ) {
                    var vector = new Vector3(x * 1.2, 0, z * 1.2);
                    vector.dist = vector.distanceTo(center);
                    vector.ratio =
                        (maxDistance - vector.dist) / (maxDistance * 0.9);
                    geom.vertices.push(vector);
                }
            }

            var mat = new PointsMaterial({
                color: 0xffffff,
                map: loader.load(dark ? textureImageLight : textureImageDark),
                transparent: true,
                alphaTest: 0.4,
                size: POINTS_EACH,
            });

            dots = new Points(geom, mat);
            container.add(dots);
        }

        var ease = {
            hole: 0,
            depth: 0,
        };
        TweenMax.to(ease, 30, {
            hole: 1,
            depth: 0.5,
            yoyo: true,
            ease: Power1.easeInOut,
            repeatDelay: 0.5,
            repeat: -1,
        });
        var animationFrameId = null;

        var each = 1;

        function render(a) {
            each++;
            if (each % 2 === 0) {
                animationFrameId = window.requestAnimationFrame(render);
                return;
            }

            animationFrameId = window.requestAnimationFrame(render);
            let vector, ratioA;

            for (var i = 0; i < dots.geometry.vertices.length; i++) {
                vector = dots.geometry.vertices[i];
                ratioA = vector.ratio * ease.depth + ease.hole;
                ratioA *=
                    vector.ratio * vector.ratio * vector.ratio * vector.ratio;
                vector.y = ratioA * -150;
                vector.y = Math.max(vector.y, -100);
                vector.y += Math.sin(-(vector.dist * 0.4) + a * 0.004);
            }

            for (var i = 0; i < plane.geometry.vertices.length; i++) {
                vector = plane.geometry.vertices[i];
                ratioA = vector.ratio * ease.depth + ease.hole;
                ratioA *=
                    vector.ratio * vector.ratio * vector.ratio * vector.ratio;
                vector.y = ratioA * -150;
                vector.y = Math.max(vector.y, -100);
                vector.y += Math.sin(-(vector.dist * 0.4) + a * 0.004);
            }

            dots.geometry.verticesNeedUpdate = true;
            plane.geometry.verticesNeedUpdate = true;

            camera.lookAt(new Vector3(0, -20, 0));

            renderer.render(scene, camera);
        }
        createDots();
        animationFrameId = window.requestAnimationFrame(render);

        window.addEventListener('resize', onResize);

        function onResize() {
            let [ww, wh] = getParentSizes();
            camera.aspect = ww / wh;
            camera.updateProjectionMatrix();
            renderer.setSize(ww, wh);
        }

        return () => {
            window.removeEventListener('resize', onResize);
            window.cancelAnimationFrame(animationFrameId);
        };
    }, [canvasRef, definition, dark, cameraY, cameraYTo, start]);

    return <canvas {...(!!className && { className })} ref={canvasRef} />;
};

export default Canvas;
